import { PlaceIcon, IconButton, Box } from '../../../imports';

import { GridColDef } from '@mui/x-data-grid';

import DataGridRestSort, { iconStyle } from '../../../components/DataGridRestSort';
import GridIconEdit from '../../../components/GridIconEdit';
import GridFieldCountryName from '../../../components/GridFieldCountryName';
import GridFieldIdentifier from '../../../components/GridFieldIdentifier';
import GridFieldName from '../../../components/GridFieldName';
import GridFieldStatus from '../../../components/GridFieldStatus';
import GridFieldType from '../../../components/GridFieldType';
import GridFieldUpdated from '../../../components/GridFieldUpdated';

import { ApiLocations } from '../../../constants/endpoints';
import { UrlLocations } from '../../../constants/urls';

import { collect_address, get_google_url } from '../../../utils/utils';

export default function LocationsList() {
  const columns: GridColDef[] = [
    GridFieldStatus({ useClosed: true }),
    GridFieldIdentifier(),
    GridFieldName(),
    GridFieldType(),
    {
      field: 'iata',
      headerName: 'IATA',
      flex: 7,
    },
    {
      field: 'uic',
      headerName: 'UIC',
      flex: 9,
    },
    {
      field: 'city',
      headerName: 'City',
      flex: 12,
      valueGetter: (params) => params.row?.city?.name,
    },
    GridFieldCountryName(),
    GridFieldUpdated(),
    {
      field: 'actions',
      headerName: '',
      minWidth: 76,
      maxWidth: 76,
      sortable: false,
      renderCell: (params) => {
        const mapUrl = get_google_url(
          params.row.coordinates?.latitude,
          params.row.coordinates?.longitude,
          collect_address(
            params.row.street,
            params.row.streetNumber,
            params.row.postcode,
            params.row.cityName,
            params.row.countryName
          ),
          '17z'
        );

        return (
          <>
            <Box component="span" width={28}>
              {mapUrl !== '' ? (
                <IconButton size="small" onClick={() => window.open(mapUrl, '_blank')}>
                  <PlaceIcon sx={iconStyle} />
                </IconButton>
              ) : (
                ' '
              )}
            </Box>
            <GridIconEdit urlEdit={UrlLocations + '/' + params.id} />
          </>
        );
      },
    },
  ];

  return (
    <DataGridRestSort
      columns={columns}
      endpoint={ApiLocations}
      navigation={UrlLocations}
      rootNode="locations"
      sortModel={{ field: 'name', sort: 'asc' }}
      filters="status,type,country,city,updated_at"
    />
  );
}
