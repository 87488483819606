import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from '../../../imports';

import EditButtons from '../../../components/EditButtons';
import HistoryCard from '../../../components/HistoryCard';
import IdentifierField from '../../../components/IdentifierField';
import LoadingPage from '../../../components/LoadingPage';
import ManufacturerAutocomplete from '../../../components/ManufacturerAutocomplete';
import ReviewCard from '../../../components/ReviewCard';
import TranslationsDrawer from '../../../components/TranslationsDrawer';
import TranslationsIcon from '../../../components/TranslationsIcon';

import AutocompleteOption from '../../../models/AutocompleteOption';
import Equipment from '../../../models/Equipment';
import EquipmentFormDataErrors from '../../../models/EquipmentFormDataErrors';
import ReviewData from '../../../models/ReviewData';
import { Translations } from '../../../models/Translations';

import { ApiEquipments } from '../../../constants/endpoints';
import { EquipmentInit } from '../../../constants/EquipmentInit';
import { EquipmentTypes } from '../../../constants/EquipmentTypes';
import { DataSendStatusInit } from '../../../constants/DataSendStatus/DataSendStatusInit';
import { LocaleEn } from '../../../constants/utils';
import { UrlEquipments } from '../../../constants/urls';

import useAxios from '../../../services/useAxios';
import { useDataSendStatus } from '../../../services/useDataSendStatus';
import usePageConfig from '../../../services/usePageConfig';

import { ucfirst } from '../../../utils/utils';
import { useValidateField } from '../../../services/useValidateField';
import alpha_num_validate from '../../../utils/validators/alpha_num';
import required_validate from '../../../utils/validators/required';
import size_max_validate from '../../../utils/validators/size_max';

export default function EquipmentsEdit() {
  const [loading, setLoading] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const [action, setAction] = useState('add');
  const [formData, setFormData] = useState<Equipment>(EquipmentInit);
  const [loadedFormData, setLoadedFormData] = useState<Equipment>(EquipmentInit);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [validateField, _setFieldError, formErrors] = useValidateField(formData, {} as EquipmentFormDataErrors);
  const { setDataSendStatus, checkResponseError } = useDataSendStatus();
  const { setTitle } = usePageConfig();
  const navigate = useNavigate();
  const { equipmentId } = useParams();
  const axiosHelper = useAxios();

  const updateLoadedFormData = useCallback(
    (data: Equipment) => {
      setLoadedFormData(data);
      setFormData(data);
      setTitle(data.name || data.identifier);
    },
    [setTitle]
  );

  function getDataFromResponse(response: any): Equipment {
    return {
      id: (response.id as string) ?? '0',
      identifier: (response.identifier as string) ?? '',
      name: (response.name as string) ?? '',
      iata: (response.iata as string) ?? '',
      icao: (response.icao as string) ?? '',
      type: (response.type as string) ?? '',
      manufacturer: (response.manufacturer as string) ?? '',
      manufacturerName: (response.manufacturerName as string) ?? '',
      status: (response.review?.status as string) ?? '',
      notes: (response.review?.notes as string) ?? '',
      created: (response.created as string) ?? '',
      updated: (response.updated as string) ?? '',
      createdBy: (response.createdBy as string) ?? '',
      updatedBy: (response.updatedBy as string) ?? '',
      statusUpdated: response.review?.statusUpdated as string,
      statusUpdatedBy: response.review?.statusUpdatedBy as string,
      translations: response?.translations || {},
    };
  }

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value, name } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleChangeSelect = (event: SelectChangeEvent): void => {
    setFormData((prevState) => ({ ...prevState, [event.target.name]: event.target.value as string }));
  };

  const handleChangeManufacturer = (newValue: AutocompleteOption | null): void => {
    setFormData((prevState) => ({
      ...prevState,
      ...(newValue
        ? { manufacturer: newValue.id, manufacturerName: newValue.name }
        : {
            manufacturer: '',
            manufacturerName: '',
          }),
    }));
  };

  const handleChangeTranslationsClassification = (data: Translations): void => {
    setFormData((prevState) => ({
      ...prevState,
      translations: {
        ...prevState.translations,
        classification: data,
      },
    }));
  };

  const handleToggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawer(open);
  };

  const validate = (): boolean => {
    let valid = true;

    valid =
      validateField('iata', (value: string) => size_max_validate(value, 10)) &&
      validateField('iata', alpha_num_validate) &&
      valid;

    valid =
      validateField('icao', (value: string) => size_max_validate(value, 10)) &&
      validateField('icao', alpha_num_validate) &&
      valid;

    valid = validateField('type', required_validate, false) && valid;

    valid =
      validateField('manufacturerName', required_validate, false) &&
      validateField('manufacturerName', (value: string) => size_max_validate(value, 255), false) &&
      valid;

    valid =
      validateField('name', required_validate, false) &&
      validateField('name', (value: string) => size_max_validate(value, 255), false) &&
      valid;

    return valid;
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validate()) {
      return;
    }

    setDataSendStatus(DataSendStatusInit);
    setSendingData(true);

    let sendData = {
      ...formData,
      review: { status: formData.status, notes: formData.notes },
    };

    let request: Promise<any>;
    if (action === 'edit') {
      request = axiosHelper.put(ApiEquipments + '/' + equipmentId, sendData);
    } else {
      request = axiosHelper.post(ApiEquipments, sendData);
    }

    request
      .then((response) => {
        if (action === 'edit') {
          setDataSendStatus({ open: true, success: true, message: 'Data updated' });
          updateLoadedFormData(getDataFromResponse(Object.values(response.data.data.equipments)[0]));
        } else {
          setDataSendStatus({ open: true, success: true, message: 'Equipment created' });
          navigate(UrlEquipments);
        }
      })
      .catch(checkResponseError)
      .finally(() => setSendingData(false));
  };

  const loadFormData = useCallback(() => {
    if (equipmentId !== '0') {
      setLoading(true);
      axiosHelper
        .get(ApiEquipments + '/' + equipmentId)
        .then((response) => {
          updateLoadedFormData(
            getDataFromResponse((Object.values(response.data.data.equipments)[0] as Equipment) ?? EquipmentInit)
          );
        })
        .catch(checkResponseError)
        .finally(() => setLoading(false));
    } else {
      setTitle('Add equipment');
    }
  }, [equipmentId, checkResponseError, axiosHelper, setTitle, updateLoadedFormData]);

  useEffect(() => {
    if (equipmentId !== '0') {
      setAction('edit');
    } else {
      setAction('add');
    }

    loadFormData();
  }, [equipmentId, loadFormData]);

  function deleteDialogCallback(result: boolean) {
    setDeleteDialogOpen(false);

    if (result) {
      setLoading(true);

      axiosHelper
        .delete(ApiEquipments + '/' + equipmentId)
        .then(() => {
          setDataSendStatus({ open: true, success: true, message: 'Equipment deleted' });
          navigate(UrlEquipments);
        })
        .catch(checkResponseError)
        .finally(() => setLoading(false));
    }
  }

  return (
    <>
      {loading && <LoadingPage />}
      {!loading && (
        <Box component="form" onSubmit={handleSubmit} sx={{ margin: 'auto', maxWidth: 1024 }}>
          <Grid container columns={2} rowSpacing={0} columnSpacing={'15px'}>
            <Grid item lg={1} xs={2}>
              <Card variant="island">
                <CardHeader title="Details" />
                <CardContent>
                  <Grid container columns={2} spacing={1}>
                    <Grid item lg={1} xs={1}>
                      <IdentifierField value={formData.identifier} />
                    </Grid>
                  </Grid>

                  <Grid container columns={2} spacing={1}>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          fullWidth
                          name="iata"
                          id="iata"
                          label="IATA code"
                          type="text"
                          value={formData.iata}
                          onChange={handleChangeInput}
                          onBlur={() => validate()}
                          inputProps={{ maxLength: 10 }}
                          error={!!formErrors.iata}
                          helperText={formErrors.iata}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          fullWidth
                          name="icao"
                          id="icao"
                          label="ICAO code"
                          type="text"
                          value={formData.icao}
                          onChange={handleChangeInput}
                          onBlur={() => validate()}
                          inputProps={{ maxLength: 10 }}
                          error={!!formErrors.icao}
                          helperText={formErrors.icao}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Divider />

                  <FormControl margin="dense" sx={{ marginBottom: 2, marginTop: 2 }} fullWidth>
                    <InputLabel id="type" error={!!formErrors.type && !formData.type}>
                      Type
                    </InputLabel>
                    <Select
                      labelId="type"
                      id="type"
                      value={formData.type}
                      label="Type"
                      name="type"
                      onChange={handleChangeSelect}
                      error={!!formErrors.type && !formData.type}
                    >
                      {EquipmentTypes.map((v) => {
                        return (
                          <MenuItem value={v} key={v} selected={v === formData.type}>
                            {ucfirst(v)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {!!formErrors.type && !formData.type && <FormHelperText error>{formErrors.type}</FormHelperText>}
                  </FormControl>

                  <Divider />

                  <ManufacturerAutocomplete
                    manufacturerName={formData.manufacturerName ?? ''}
                    onChangeValue={handleChangeManufacturer}
                  />

                  <FormControl margin="dense" fullWidth>
                    <TextField
                      fullWidth
                      name="name"
                      id="name"
                      label="Name"
                      type="text"
                      value={formData.name}
                      onChange={handleChangeInput}
                      error={!!formErrors.name && !formData.name}
                      helperText={formErrors.name}
                    />
                  </FormControl>

                  <FormControl margin="dense" fullWidth>
                    <InputLabel htmlFor="classification">Further classification</InputLabel>
                    <OutlinedInput
                      fullWidth
                      readOnly
                      name="classification"
                      id="classification"
                      label="Further classification"
                      type="text"
                      value={
                        (formData.translations.classification && formData.translations.classification[LocaleEn]) || ''
                      }
                      onClick={handleToggleDrawer(true)}
                      onBlur={() => validate()}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton edge="end">
                            <TranslationsIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      sx={{
                        '& .MuiInputBase-input': {
                          color: 'black',
                        },
                      }}
                      error={!!formErrors.classification}
                    />
                    {!!formErrors.classification && <FormHelperText>{formErrors.classification}</FormHelperText>}
                  </FormControl>

                  <TranslationsDrawer
                    name="classification"
                    label="Further classification"
                    open={drawer}
                    translations={formData.translations.classification}
                    onChange={handleChangeTranslationsClassification}
                    onClose={() => {
                      setDrawer(false);
                      validate();
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>

            <Grid item lg={1} xs={2}>
              <ReviewCard
                action={action}
                initialStatus={loadedFormData.status}
                statusUpdated={formData.statusUpdated}
                statusUpdatedBy={formData.statusUpdatedBy}
                created={formData.created}
                notes={formData.notes}
                status={formData.status}
                onChange={(data: ReviewData) => setFormData({ ...formData, notes: data.notes, status: data.status })}
              />

              {action === 'edit' && (
                <HistoryCard
                  createdBy={formData.createdBy}
                  created={formData.created}
                  updatedBy={formData.updatedBy}
                  updated={formData.updated}
                />
              )}
            </Grid>
          </Grid>

          <EditButtons
            action={action}
            itemName={loadedFormData.name}
            entityName="equipment"
            sendingData={sendingData}
            deleteDialogOpen={deleteDialogOpen}
            deleteDialogCallback={deleteDialogCallback}
            onReset={() => updateLoadedFormData(loadedFormData)}
            onDelete={() => setDeleteDialogOpen(true)}
          />
        </Box>
      )}
    </>
  );
}
