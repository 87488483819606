import { useEffect, useState } from 'react';
import { CircleFlag } from 'react-circle-flags';

import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  ClearIcon,
  CloseIcon,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '../imports';

import { LocaleEn } from '../constants/utils';

import { useAuth } from '../services/useAuth';

import { arraysEqual } from '../utils/utils';

interface LocalizedValues {
  [key: string]: string[];
}

interface TranslationsDrawerMultipleProps {
  name: string;
  label: string;
  open: boolean;
  globalDesignation?: boolean;
  translations?: LocalizedValues;
  onChange?: (data: LocalizedValues) => void;
  onClose?: (open: boolean) => void;
  rows?: number;
}

export default function TranslationsDrawerMultiple({
  label,
  name,
  open,
  globalDesignation,
  translations,
  onChange,
  onClose,
}: TranslationsDrawerMultipleProps) {
  const { user } = useAuth();

  const locales = Object.entries(user.supported_locales).map(([locale, language]) => ({
    code: locale,
    countryCode: locale.substring(3, 5).toLowerCase(),
    languageCode: locale.substring(0, 2).toLowerCase(),
    languageName: language,
  }));

  let tmp: LocalizedValues = {};
  locales.forEach((locale) => {
    tmp[locale.code] = (translations && translations[locale.code]) ?? [];
  });

  const [globalDesignationValue, setGlobalDesignationValue] = useState(() => {
    const values = Object.values(tmp);
    return globalDesignation !== undefined && values.every((value) => arraysEqual(value, values[0]));
  });

  const [data, setData] = useState(translations || {});

  const defaultLocale = locales.find((locale) => locale.code === LocaleEn);

  const handleAutocompleteChange = (locale: string) => (_: any, value: string[] | null) => {
    if (locale === defaultLocale?.code && (!value || value.length === 0) && globalDesignationValue) {
      let clearedLocales: LocalizedValues = {};
      locales.forEach((locale) => {
        clearedLocales[locale.code] = [];
      });
      setData(clearedLocales);
      setGlobalDesignationValue(false);
    } else {
      setData((prevState) => ({ ...prevState, [locale]: value || [] }));
    }
  };

  const handleChangeGlobalDesignation = (checked: boolean): void => {
    const defaultLocaleValue = defaultLocale && data[defaultLocale.code];

    if (!defaultLocaleValue || defaultLocaleValue.length === 0) {
      return;
    }

    setGlobalDesignationValue(checked);

    if (checked) {
      let tmp: LocalizedValues = {};
      locales.forEach((locale) => {
        tmp[locale.code] = (defaultLocale && data[defaultLocale.code]) ?? [];
      });
      setData((prevState) => ({ ...prevState, ...tmp }));
    } else {
      setData(translations || {});
    }
  };
  const handleChange = (): void => {
    onChange && onChange(data);
    onClose && onClose(false);
  };

  const handleCancel = (): void => {
    setData(translations || {});
    onClose && onClose(false);
  };

  const handleToggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    onClose && onClose(open);
  };

  useEffect(() => {
    setData(translations || {});
  }, [translations]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleToggleDrawer(false)}
      PaperProps={{ sx: { backgroundColor: '#EFEFEF', borderRadius: 0, p: 4 } }}
    >
      <Box className="RightDrawerContent">
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginBottom: 4 }}>
          <Typography fontSize="18px">{label}</Typography>
          <IconButton aria-label="close" size="small" onClick={handleToggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Card
          variant="island"
          sx={{
            marginX: 10,
            paddingTop: 1.5,
            paddingBottom: 2,
            '& .MuiCardContent-root:last-child': { paddingBottom: 0 },
          }}
        >
          <CardContent>
            <FormControl margin="dense" sx={{ marginBottom: 1 }} fullWidth>
              <Autocomplete
                multiple
                freeSolo
                options={[]}
                value={data[defaultLocale?.code ?? ''] || ''}
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip
                      variant="transfer"
                      color="primary"
                      label={option}
                      deleteIcon={<ClearIcon />}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                onChange={handleAutocompleteChange(defaultLocale?.code ?? '')}
                renderInput={(params) => (
                  <TextField
                    autoFocus
                    name={name + '_' + defaultLocale?.code}
                    {...params}
                    label={defaultLocale?.languageName + ' / International'}
                    InputProps={{
                      style: { gap: '3px', paddingRight: '10px' },
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          <CircleFlag
                            countryCode={defaultLocale?.countryCode ?? ''}
                            title={defaultLocale?.languageName}
                            height={20}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>

            {globalDesignation !== undefined && (
              <FormControlLabel
                label="Global designation"
                control={
                  <Checkbox
                    disabled={!defaultLocale || !data[defaultLocale.code] || data[defaultLocale.code].length === 0}
                    checked={globalDesignationValue}
                    onChange={(event) => handleChangeGlobalDesignation(event.target.checked)}
                  />
                }
              />
            )}

            <Divider />
            {locales
              .filter((locale) => locale !== defaultLocale)
              .map((locale, key) => (
                <FormControl key={key} margin="dense" sx={key === 0 ? { marginTop: 2 } : {}} fullWidth>
                  <Autocomplete
                    freeSolo
                    multiple
                    options={[]}
                    value={data[locale.code] || ''}
                    renderTags={(value: readonly string[], getTagProps) =>
                      value.map((option: string, index: number) => (
                        <Chip
                          variant="transfer"
                          color="primary"
                          label={option}
                          deleteIcon={<ClearIcon />}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    onChange={handleAutocompleteChange(locale.code)}
                    disabled={globalDesignationValue}
                    renderInput={(params) => (
                      <TextField
                        name={name + '_' + locale.code}
                        {...params}
                        label={locale.languageName}
                        InputProps={{
                          style: { gap: '3px', paddingRight: '10px' },
                          ...params.InputProps,
                          endAdornment: (
                            <InputAdornment position="end">
                              <CircleFlag countryCode={locale.countryCode} title={locale.languageName} height={20} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              ))}
          </CardContent>
        </Card>
        <Stack direction="row" ml={1} sx={{ mt: 3, mx: 8 }}>
          <Button color="primary" variant="contained" sx={{ ml: 2 }} onClick={handleChange}>
            Change
          </Button>
          <Button variant="elevated" sx={{ ml: 2 }} onClick={handleCancel}>
            Cancel
          </Button>
        </Stack>
      </Box>
    </Drawer>
  );
}
