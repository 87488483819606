import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  ClearIcon,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from '../../../imports';

import AliasesAutocomplete from '../../../components/AliasesAutocomplete';
import AutocompleteLoading from '../../../components/Autocomplete/AutocompleteLoading';
import CodeCconField from '../../../components/CodeCconField';
import EditButtons from '../../../components/EditButtons';
import HistoryCard from '../../../components/HistoryCard';
import IdentifierField from '../../../components/IdentifierField';
import LoadingPage from '../../../components/LoadingPage';
import NameField from '../../../components/NameField';
import OpenMapButton from '../../../components/OpenMapButton';
import ReviewCard from '../../../components/ReviewCard';
import SimpleNumberField from '../../../components/SimpleNumberField';
import TranslationsDrawer from '../../../components/TranslationsDrawer';
import TranslationsDrawerMultiple from '../../../components/TranslationsDrawerMultiple';

import AutocompleteOption from '../../../models/AutocompleteOption';
import City, { LocalizedValues } from '../../../models/City';
import CityFormDataErrors from '../../../models/CityFormDataErrors';
import { Translations } from '../../../models/Translations';

import { ApiCities, ApiCountriesSearch } from '../../../constants/endpoints';
import { CityInit } from '../../../constants/CityInit';
import { DataSendStatusInit } from '../../../constants/DataSendStatus/DataSendStatusInit';
import { LocaleEn } from '../../../constants/utils';
import { UrlCities } from '../../../constants/urls';

import useAxios from '../../../services/useAxios';
import { useDataSendStatus } from '../../../services/useDataSendStatus';
import usePageConfig from '../../../services/usePageConfig';
import { useValidateField } from '../../../services/useValidateField';

import un_locode_validate from '../../../utils/validators/un_locode';
import alpha_num_validate from '../../../utils/validators/alpha_num';
import integer_validate from '../../../utils/validators/integer';
import numeric_validate from '../../../utils/validators/numeric';
import required_validate from '../../../utils/validators/required';
import size_max_validate from '../../../utils/validators/size_max';
import size_validate from '../../../utils/validators/size';
import url_validate from '../../../utils/validators/url';
import without_diacritics_validate from '../../../utils/validators/without_diacritics';
import { getAliasesDiff } from '../../../utils/utils';

export default function CitiesEdit() {
  const [loading, setLoading] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [action, setAction] = useState('add');
  const [formData, setFormData] = useState<City>(CityInit);
  const [loadedFormData, setLoadedFormData] = useState<City>(CityInit);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [drawers, setDrawers] = useState({
    name: false,
    address: false,
    aliases: false,
  });

  const [prevAliases, setPrevAliases] = useState<LocalizedValues | undefined>();

  const [validateField, setFieldError, formErrors] = useValidateField(formData, {} as CityFormDataErrors);
  const { setDataSendStatus, checkResponseError } = useDataSendStatus();
  const { setTitle } = usePageConfig();
  const navigate = useNavigate();
  const { cityId } = useParams();
  const axiosHelper = useAxios();

  function onChangeUnLocode(e: ChangeEvent<HTMLInputElement>) {
    let { value, name } = e.target;

    if (value.length > 2 && value[2] !== ' ') {
      value = value.slice(0, 2) + ' ' + value.slice(2, value.length);
    }

    setFormData((prevState) => ({ ...prevState, [name]: value.toUpperCase() }));
  }

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    const { value, name } = e.target;

    setFormData((prevState) => ({ ...prevState, [name]: value }));
  }

  function onCapitalChange(e: ChangeEvent<HTMLInputElement>) {
    const { checked } = e.target;

    setFormData((prevState) => ({ ...prevState, capital: checked }));
  }

  const onCountryChangeValue = (newValue: AutocompleteOption | null) => {
    setFormData((prevState) => ({
      ...prevState,
      country: newValue
        ? {
            identifier: newValue.id,
            name: newValue.name,
          }
        : { identifier: '', name: '' },
    }));
  };

  const onMultiselectChange = (name: string, newValue: string[]) => {
    setFormData((prevState) => ({ ...prevState, [name]: newValue }));
  };

  const updateLoadedFormData = useCallback(
    (data: City) => {
      setLoadedFormData(data);
      setFormData(data);
      setTitle(data.name || data.identifier);
    },
    [setTitle]
  );

  function getDataFromResponse(response: any): City {
    return {
      id: (response.id as string) ?? '0',
      identifier: (response.identifier as string) ?? '',
      iata: (response.iata as string) ?? '',
      name: (response.name as string) ?? '',
      aliases: response.aliases ?? [],
      country: response.country ?? {},
      iso: (response.iso as string) ?? '',
      unlo: (response.unlo as string) ?? '',
      ccon: (response.ccon as string) ?? '',
      nameWithoutDiacritics: (response.nameWithoutDiacritics as string) ?? '',
      callingCodes: (response.callingCodes as string[]) ?? [],
      licensePlates: (response.licensePlates as string[]) ?? [],
      capital: Boolean(response.capital),
      latitude: response.coordinates.latitude as string,
      longitude: response.coordinates.longitude as string,
      created: response.created as string,
      updated: response.updated as string,
      createdBy: response.createdBy as string,
      updatedBy: response.updatedBy as string,
      status: (response.review?.status as string) ?? '',
      notes: (response.review?.notes as string) ?? '',
      statusUpdated: response.review?.statusUpdated as string,
      statusUpdatedBy: response.review?.statusUpdatedBy as string,
      website: (response.weblinks.website as string) ?? '',
      tourismOffice: (response.weblinks.tourismOffice as string) ?? '',
      translations: response?.translations || {},
    };
  }

  const handleTranslationsAliasesChange = (data: LocalizedValues) => {
    setFormData((prevState) => ({
      ...prevState,
      aliases: {
        ...prevState.aliases,
        ...data,
      },
    }));
  };

  const handleTranslationsChange = (data: Translations): void => {
    setFormData((prevState) => ({
      ...prevState,
      name: data[LocaleEn] || '',
      translations: {
        ...prevState.translations,
        name: data,
      },
    }));
  };

  const handleToggleDrawer = (drawer: string, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setDrawers((prev) => ({ ...prev, [drawer]: open }));
  };

  const onAliasesChangeValue = (newValue: string[]) => {
    setFormData((prevState) => ({
      ...prevState,
      aliases: { en_GB: newValue || [] },
    }));
  };

  const validate = (): boolean => {
    let valid = true;

    valid =
      validateField('iata', (value: string) => size_validate(value, 3)) &&
      validateField('iata', alpha_num_validate) &&
      valid;

    valid =
      validateField('iso', (value: string) => size_max_validate(value, 10)) &&
      validateField('iso', alpha_num_validate) &&
      valid;

    valid = validateField('unlo', un_locode_validate) && valid;

    valid = validateField('nameWithoutDiacritics', without_diacritics_validate) && valid;

    for (const i in formData.callingCodes) {
      const value = formData.callingCodes[i];
      const error = integer_validate(value);

      valid = setFieldError('callingCodes', value, true, error) && valid;

      if (error !== '') {
        break;
      }
    }

    for (const i in formData.licensePlates) {
      const value = formData.licensePlates[i];
      const error = alpha_num_validate(value);

      valid = setFieldError('licensePlates', value, true, error) && valid;

      if (error !== '') {
        break;
      }
    }

    valid = validateField('website', url_validate) && valid;
    valid = validateField('tourismOffice', url_validate) && valid;

    valid = validateField('country', required_validate, false) && valid;

    valid = validateField('latitude', numeric_validate) && valid;
    valid = validateField('longitude', numeric_validate) && valid;

    return valid;
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validate()) {
      return;
    }

    setDataSendStatus(DataSendStatusInit);
    setSendingData(true);

    const { added, removed } = getAliasesDiff(prevAliases || {}, formData.aliases);

    let sendData = {
      ...formData,
      country: formData.country.identifier,

      aliases: {
        add: added,
        remove: removed,
      },
      coordinates: { latitude: formData.latitude, longitude: formData.longitude },
      weblinks: { website: formData.website, tourismOffice: formData.tourismOffice },
    };

    let request: Promise<any>;
    if (action === 'edit') {
      request = axiosHelper.put(ApiCities + '/' + cityId, sendData);
    } else {
      request = axiosHelper.post(ApiCities, sendData);
    }

    request
      .then((response) => {
        if (action === 'edit') {
          setDataSendStatus({ open: true, success: true, message: 'Data updated' });
          const d = getDataFromResponse((response.data.data.cities[0] as City) ?? CityInit);
          updateLoadedFormData(d);
        } else {
          setDataSendStatus({ open: true, success: true, message: 'City created' });
          navigate(UrlCities);
        }
      })
      .catch(checkResponseError)
      .finally(() => setSendingData(false));
  };

  const loadFormData = useCallback(() => {
    if (cityId !== '0') {
      setLoading(true);
      axiosHelper
        .get(ApiCities + '/' + cityId)
        .then((response) => {
          setPrevAliases(response.data.data.cities[0].aliases);
          const d = getDataFromResponse((response.data.data.cities[0] as City) ?? CityInit);
          updateLoadedFormData(d);
        })
        .catch(checkResponseError)
        .finally(() => setLoading(false));
    } else {
      setTitle('Add city');
    }
  }, [cityId, axiosHelper, setTitle, updateLoadedFormData]);

  useEffect(() => {
    if (cityId !== '0') {
      setAction('edit');
    } else {
      setAction('add');
    }

    loadFormData();
  }, [cityId, loadFormData]);

  function deleteDialogCallback(result: boolean) {
    setDeleteDialogOpen(false);

    if (result) {
      setLoading(true);

      axiosHelper
        .delete(ApiCities + '/' + cityId)
        .then(() => {
          setDataSendStatus({ open: true, success: true, message: 'City deleted' });
          navigate(UrlCities);
        })
        .catch(checkResponseError)
        .finally(() => setLoading(false));
    }
  }

  return (
    <>
      {loading && <LoadingPage />}
      {!loading && (
        <Box component="form" onSubmit={handleSubmit} sx={{ margin: 'auto', maxWidth: 1024 }}>
          <Grid container columns={2} rowSpacing={0} columnSpacing={'15px'}>
            <Grid item lg={1} xs={2}>
              <Card variant="island">
                <CardHeader title="City" />
                <CardContent>
                  <Grid container columns={2} spacing={1}>
                    <Grid item lg={1} xs={1}>
                      <IdentifierField value={formData.identifier} />
                    </Grid>
                  </Grid>
                  <Grid container columns={2} spacing={1}>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          fullWidth
                          name="iata"
                          id="iata"
                          label="IATA code"
                          type="text"
                          value={formData.iata}
                          onChange={onChange}
                          onBlur={() => validate()}
                          inputProps={{ maxLength: 3 }}
                          error={!!formErrors.iata}
                          helperText={formErrors.iata}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          fullWidth
                          name="iso"
                          id="iso"
                          label="ISO code"
                          type="text"
                          value={formData.iso}
                          onChange={onChange}
                          onBlur={() => validate()}
                          inputProps={{ maxLength: 10 }}
                          error={!!formErrors.iso}
                          helperText={formErrors.iso}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container columns={2} spacing={1} sx={{ marginBottom: 2.5 }}>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          fullWidth
                          name="unlo"
                          id="unlo"
                          label="UN/LOCODE"
                          type="text"
                          value={formData.unlo}
                          onChange={onChangeUnLocode}
                          onBlur={() => validate()}
                          error={!!formErrors.unlo}
                          helperText={formErrors.unlo}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item lg={1} xs={1}>
                      <CodeCconField value={formData.ccon} />
                    </Grid>
                  </Grid>

                  <Divider />

                  <FormControl margin="dense" fullWidth sx={{ marginLeft: 1, marginTop: 1, marginBottom: 1 }}>
                    <FormControlLabel
                      control={<Switch checked={formData.capital} onChange={onCapitalChange} />}
                      label="Capital"
                    />
                  </FormControl>

                  <Divider />

                  <Box margin="dense" sx={{ marginTop: 2 }}>
                    <NameField
                      translations={formData.translations.name}
                      value={(formData.translations.name && formData.translations.name[LocaleEn]) || ''}
                      onClick={handleToggleDrawer('name', true)}
                    />

                    <TranslationsDrawer
                      name="name"
                      label="Name"
                      open={drawers.name}
                      globalDesignation={true}
                      translations={formData.translations.name}
                      onChange={handleTranslationsChange}
                      onClose={() => setDrawers((prev) => ({ ...prev, name: false }))}
                    />
                  </Box>

                  <FormControl margin="dense" fullWidth>
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="nameWithoutDiacritics"
                      id="nameWithoutDiacritics"
                      label="Name without diacritical marks"
                      type="text"
                      value={formData.nameWithoutDiacritics}
                      onChange={onChange}
                      onBlur={() => validate()}
                      error={!!formErrors.nameWithoutDiacritics}
                      helperText={formErrors.nameWithoutDiacritics}
                    />
                  </FormControl>

                  <AliasesAutocomplete
                    value={formData.aliases}
                    onChange={onAliasesChangeValue}
                    onTranslationsClick={handleToggleDrawer('aliases', true)}
                    translations={formData.aliases}
                  />

                  <TranslationsDrawerMultiple
                    name="Aliases"
                    label="Aliases"
                    open={drawers.aliases}
                    globalDesignation={true}
                    translations={formData.aliases || {}}
                    onChange={handleTranslationsAliasesChange}
                    onClose={() => setDrawers((prev) => ({ ...prev, aliases: false }))}
                  />

                  <Divider />

                  <Autocomplete
                    fullWidth
                    multiple
                    id="callingCodes"
                    options={[]}
                    freeSolo
                    value={formData.callingCodes}
                    renderTags={(value: readonly string[], getTagProps) =>
                      value.map((option: string, index: number) => (
                        <Chip
                          variant="transfer"
                          color="primary"
                          label={option}
                          deleteIcon={<ClearIcon />}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ marginTop: 2 }}
                        variant="outlined"
                        label="Calling codes"
                        margin="dense"
                        error={!!formErrors.callingCodes}
                        helperText={formErrors.callingCodes}
                      />
                    )}
                    onBlur={() => validate()}
                    onChange={(_e, value) => {
                      onMultiselectChange('callingCodes', value);
                      validate();
                    }}
                  />

                  <Autocomplete
                    fullWidth
                    multiple
                    id="licensePlates"
                    options={[]}
                    freeSolo
                    value={formData.licensePlates}
                    renderTags={(value: readonly string[], getTagProps) =>
                      value.map((option: string, index: number) => (
                        <Chip
                          variant="transfer"
                          color="primary"
                          label={option}
                          deleteIcon={<ClearIcon />}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="License plates"
                        margin="dense"
                        error={!!formErrors.licensePlates}
                        helperText={formErrors.licensePlates}
                      />
                    )}
                    onBlur={() => validate()}
                    onChange={(_e, value) => {
                      onMultiselectChange('licensePlates', value);
                      validate();
                    }}
                  />
                </CardContent>
              </Card>

              <Card variant="island">
                <CardHeader title="Weblinks" />
                <CardContent>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      fullWidth
                      name="website"
                      id="website"
                      label="Website"
                      type="text"
                      value={formData.website}
                      onChange={onChange}
                      onBlur={() => validate()}
                      error={!!formErrors.website}
                      helperText={formErrors.website}
                    />
                  </FormControl>

                  <FormControl margin="dense" fullWidth>
                    <TextField
                      fullWidth
                      name="tourismOffice"
                      id="tourismOffice"
                      label="Tourism office"
                      type="text"
                      value={formData.tourismOffice}
                      onChange={onChange}
                      onBlur={() => validate()}
                      error={!!formErrors.tourismOffice}
                      helperText={formErrors.tourismOffice}
                    />
                  </FormControl>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={1} xs={2}>
              <Card variant="island">
                <CardHeader
                  title="Geo"
                  action={<OpenMapButton latitude={formData.latitude} longitude={formData.longitude} />}
                />
                <CardContent>
                  <AutocompleteLoading
                    urlPrefix={ApiCountriesSearch}
                    label="Country"
                    loadAll={true}
                    value={formData.country.identifier ?? ''}
                    valueName={formData.country.name ?? ''}
                    onChangeValue={onCountryChangeValue}
                    onInputBlur={() => validate()}
                    error={!!formErrors.country}
                    helperText={formErrors.country}
                  />

                  <Grid container columns={2} spacing={1}>
                    <Grid item lg={1} xs={1}>
                      <SimpleNumberField
                        fullWidth
                        margin="dense"
                        name="latitude"
                        id="latitude"
                        label="Latitude"
                        type="text"
                        value={formData.latitude}
                        onChange={onChange}
                        onBlur={() => validate()}
                        error={!!formErrors.latitude}
                        helperText={formErrors.latitude}
                      />
                    </Grid>
                    <Grid item lg={1} xs={1}>
                      <SimpleNumberField
                        fullWidth
                        margin="dense"
                        name="longitude"
                        id="longitude"
                        label="Longitude"
                        type="text"
                        value={formData.longitude}
                        onChange={onChange}
                        onBlur={() => validate()}
                        error={!!formErrors.longitude}
                        helperText={formErrors.longitude}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <ReviewCard
                action={action}
                initialStatus={loadedFormData.status}
                statusUpdated={formData.statusUpdated}
                statusUpdatedBy={formData.statusUpdatedBy}
                created={formData.created}
                notes={formData.notes}
                status={formData.status}
                onChange={(data) => setFormData({ ...formData, notes: data.notes, status: data.status })}
              />
              {action === 'edit' && (
                <HistoryCard
                  createdBy={formData.createdBy}
                  created={formData.created}
                  updatedBy={formData.updatedBy}
                  updated={formData.updated}
                />
              )}
            </Grid>
          </Grid>

          <EditButtons
            action={action}
            itemName={loadedFormData.name}
            entityName="city"
            sendingData={sendingData}
            deleteDialogOpen={deleteDialogOpen}
            deleteDialogCallback={deleteDialogCallback}
            onReset={() => updateLoadedFormData(loadedFormData)}
            onDelete={() => setDeleteDialogOpen(true)}
          />
        </Box>
      )}
    </>
  );
}
