import { TextFieldProps, TextField } from '../imports';

export default function SimpleNumberField(props: { onlyInteger?: boolean } & TextFieldProps) {
  const { onlyInteger, onChange, ...rest } = props;
  const regexp = onlyInteger ? /^-?[0-9]*$/ : /^-?[0-9]*\.?[0-9]*$/;

  return (
    <TextField
      {...rest}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange && regexp.test(e.target.value)) {
          onChange(e);
        }
      }}
    />
  );
}
