import { useEffect, useMemo, useState } from 'react';
import { debounce } from '@mui/material/utils';

import { Autocomplete, TextField, createFilterOptions } from '../imports';

import AutocompleteOption from '../models/AutocompleteOption';

import { ApiManufacturersSearch } from '../constants/endpoints';

import useAxios from '../services/useAxios';
import { useDataSendStatus } from '../services/useDataSendStatus';

const filter = createFilterOptions<AutocompleteOption>();

interface ManufacturerAutocompleteProps {
  manufacturerName?: string;
  onChangeValue: (value: AutocompleteOption | null) => void;
}

export default function ManufacturerAutocomplete(props: ManufacturerAutocompleteProps) {
  const initData: AutocompleteOption = {
    id: '',
    name: props.manufacturerName ?? '',
    inputValue: '',
  };
  const [value, setValue] = useState<AutocompleteOption | null>(initData);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadedData, _setLoadedData] = useState<AutocompleteOption[]>([]);
  const [options, setOptions] = useState<AutocompleteOption[]>([]);

  const axiosHelper = useAxios();
  const { checkResponseError } = useDataSendStatus();

  const fetch = useMemo(
    () =>
      debounce((request: string, callback: (results?: readonly AutocompleteOption[]) => void) => {
        let url = ApiManufacturersSearch;

        if (request) {
          url += '?search=' + encodeURIComponent(request);
        }

        setLoading(true);
        axiosHelper
          .get(url)
          .then((response) => {
            callback(
              response.data.data.map((v: any) => {
                return { id: v.id as string, name: v.name as string };
              })
            );
          })
          .catch(checkResponseError)
          .finally(() => setLoading(false));
      }, 500),
    [checkResponseError, axiosHelper]
  );

  useEffect(() => {
    let active = true;

    if (!loadedData.length) {
      fetch(inputValue, (results?: readonly AutocompleteOption[]) => {
        if (active) {
          let newOptions: AutocompleteOption[] = [];

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      });
    }

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, loadedData.length]);

  return (
    <Autocomplete
      value={value}
      sx={{ marginBottom: 2, marginTop: 2 }}
      onChange={(_event, newValue) => {
        if (typeof newValue === 'string') {
          setValue({
            id: '',
            name: newValue,
          });
          props.onChangeValue({
            id: '',
            name: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValue({
            id: '',
            name: newValue.inputValue,
          });
          props.onChangeValue({
            id: '',
            name: newValue.inputValue,
          });
        } else {
          setValue(newValue);
          props.onChangeValue(newValue);
        }
      }}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.name);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            id: '',
            name: 'Add "' + inputValue + '"',
            inputValue: inputValue,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      loading={loading}
      options={options}
      noOptionsText="No matches"
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      renderOption={(props, option) => (
        <li {...props} style={{ maxHeight: '25px' }}>
          {option.name}
        </li>
      )}
      renderInput={(params) => <TextField {...params} label="Manufacturer" />}
    />
  );
}
