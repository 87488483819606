import { GridColDef } from '@mui/x-data-grid';

import DataGridRestSort from '../../../components/DataGridRestSort';
import GridFieldActions from '../../../components/GridFieldActions';
import GridFieldIdentifier from '../../../components/GridFieldIdentifier';
import GridFieldName from '../../../components/GridFieldName';
import GridFieldStatus from '../../../components/GridFieldStatus';
import GridFieldUpdated from '../../../components/GridFieldUpdated';

import { ApiCountries } from '../../../constants/endpoints';
import { UrlCountries } from '../../../constants/urls';

export default function CountriesList() {
  const columns: GridColDef[] = [
    GridFieldStatus(),
    GridFieldIdentifier(),
    GridFieldName(),
    { field: 'iso', headerName: 'ISO code', flex: 7 },
    { field: 'alpha3', headerName: 'ISO alpha-3 code', flex: 9 },
    { field: 'numeric', headerName: 'ISO numeric code', flex: 9 },
    {
      field: 'continentName',
      headerName: 'Continent',
      flex: 8,
      valueGetter: (params) => params.row?.continent?.name,
    },
    GridFieldUpdated(),
    GridFieldActions({ baseUrlEdit: UrlCountries }),
  ];

  return (
    <DataGridRestSort
      columns={columns}
      endpoint={ApiCountries}
      navigation={UrlCountries}
      rootNode="countries"
      sortModel={{ field: 'name', sort: 'asc' }}
    />
  );
}
