import { Autocomplete, Box, Chip, ClearIcon, InputAdornment, IconButton, TextField } from '../imports';

import TranslationsIcon from './TranslationsIcon';

import { LocalizedValues } from '../models/Location';
import { useAuth } from '../services/useAuth';
import { arraysEqual } from '../utils/utils';

interface AliasesAutocompleteProps {
  value: LocalizedValues;
  onChange: (value: string[]) => void;
  onTranslationsClick: (event: React.KeyboardEvent | React.MouseEvent) => void;
  translations?: LocalizedValues;
}

export default function AliasesAutocomplete({
  value,
  onChange,
  onTranslationsClick,
  translations = {},
}: AliasesAutocompleteProps) {
  const { user } = useAuth();

  const translationsCount = translations ? Object.keys(value).filter((key) => value[key].length > 0) : 0;

  const firstArray = Object.values(translations)[0];
  const allEqual = Object.values(translations).every((arr) => arraysEqual(arr, firstArray));

  const globalChip =
    translationsCount && translationsCount.length === Object.keys(user.supported_locales).length && allEqual;

  const localesKeys = Object.keys(value).filter((key) => value[key].length > 0);

  return (
    <Box onClick={onTranslationsClick}>
      <Autocomplete
        fullWidth
        multiple
        clearIcon={false}
        id="aliases"
        options={[]}
        readOnly
        freeSolo
        value={localesKeys || []}
        renderTags={(value: readonly string[], getTagProps) =>
          value &&
          value.map((option: string, index: number) => (
            <Chip
              sx={{
                backgroundColor: '#fff',
                border: '1px solid #B7B8B9',
                color: '#B7B8B9',
              }}
              label={option}
              deleteIcon={<ClearIcon />}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            multiline
            {...params}
            sx={{ marginBottom: 2 }}
            variant="outlined"
            label="Aliases"
            margin="dense"
            InputProps={{
              style: { paddingRight: '5px', gap: '3px' },
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  <InputAdornment position="end">
                    {globalChip && (
                      <Chip
                        sx={{
                          width: '56px',
                          height: '22px',
                          color: '#B7B8B9',
                          backgroundColor: '#fff',
                          border: '1px solid #B7B8B9',
                          borderRadius: '8px',
                        }}
                        label="global"
                      />
                    )}
                  </InputAdornment>
                  <InputAdornment position="end">
                    <IconButton color="primary">
                      <TranslationsIcon
                        color={
                          translationsCount && translationsCount.length === 0
                            ? 'GRAY'
                            : translationsCount &&
                              translationsCount.length === Object.entries(user.supported_locales).length
                            ? 'GREEN'
                            : 'ORANGE'
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                </>
              ),
            }}
          />
        )}
        onChange={(_e, newValue) => {
          if (newValue) {
            onChange(newValue as string[]);
          }
        }}
      />
    </Box>
  );
}
