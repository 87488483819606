import { useState, DragEvent, ChangeEvent, useRef, SyntheticEvent } from 'react';
import { Box, Button, FileUploadIcon, InputBase, InputLabel, Typography } from '../imports';

import { useDataSendStatus } from '../services/useDataSendStatus';

interface ImageFieldProps {
  name: string;
  data: File | string;
  format?: string;
  mime?: string;
  setData(data: File | string): void;
}

interface ImageProps {
  width: number;
  height: number;
  size: number;
}

export default function ImageField(props: ImageFieldProps) {
  const { setDataSendStatus } = useDataSendStatus();
  const [drag, setDrag] = useState(false);
  const [imageProps, setImageProps] = useState<ImageProps | null>();
  const imageRef = useRef<HTMLImageElement>();
  const fileRef = useRef<HTMLInputElement>();

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    event.stopPropagation();

    if (event.currentTarget && event.currentTarget.files && event.currentTarget.files[0]) {
      const file = event.currentTarget.files[0];
      if (file.type.startsWith('image/')) {
        props.setData(file);
        setDataSendStatus({ open: true, success: true, message: 'Image uploaded' });
      } else {
        setDataSendStatus({ open: true, success: false, message: 'Please upload a valid image file.' });
      }
      event.currentTarget.value = '';
    }
  }

  function onDrag(event: DragEvent<HTMLDivElement>) {
    event.preventDefault();
    event.stopPropagation();

    if (event.type === 'dragenter' || event.type === 'dragover') {
      setDrag(true);
    } else if (event.type === 'dragleave') {
      setDrag(false);
    }
  }

  function onDrop(event: DragEvent<HTMLDivElement>) {
    event.preventDefault();
    event.stopPropagation();

    setDrag(false);

    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const file = event.dataTransfer.files[0];
      if (file.type.startsWith('image/')) {
        props.setData(file);
        setDataSendStatus({ open: true, success: true, message: 'Image uploaded' });
      } else {
        setDataSendStatus({ open: true, success: false, message: 'Please upload a valid image file.' });
      }
    }
  }

  const dragOptions = {
    onDragEnter: onDrag,
    onDragLeave: onDrag,
    onDragOver: onDrag,
    onDrop: onDrop,
  };

  const handleImageLoad = (event: SyntheticEvent<HTMLImageElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    imageRef.current &&
      setImageProps({
        width: imageRef.current.naturalWidth,
        height: imageRef.current.naturalHeight,
        size: props.data instanceof File ? props.data.size : (props.data.length / 8) * 6,
      });
  };

  let imageData = '';
  let imageMime = '';
  let imageFormat = '';

  if (props.data) {
    if (props.data instanceof File) {
      imageFormat = props.data.name.substring(props.data.name.lastIndexOf('.') + 1);
      imageData = URL.createObjectURL(props.data);
    } else {
      imageMime = props.mime || 'image/png';
      imageFormat = props.mime ? props.mime.substring(props.mime.indexOf('/') + 1) : 'unknown';
      imageData = 'data:' + imageMime + ';base64, ' + props.data;
    }
  }

  return (
    <>
      {props.data && (
        <>
          <Box
            component="img"
            ref={imageRef}
            alt="Logo"
            src={imageData}
            {...dragOptions}
            sx={{
              maxWidth: '100%',
              maxHeight: '458px',
              cursor: 'pointer',
              border: '1px dashed #859BD6',
              borderRadius: '6px',
              padding: '4px',
              ...(drag && { opacity: 0.5 }),
            }}
            onClick={() => fileRef.current?.click()}
            onLoad={(event) => handleImageLoad(event)}
          />

          {imageProps && (
            <Typography sx={{ mt: 1, textAlign: 'left' }} fontSize="12px">
              Format: <b>{imageFormat.toUpperCase()}</b>
              <br />
              Dimensions:{' '}
              <b>
                {imageProps.width} x {imageProps.height}
              </b>
              <br />
              Size: <b>{Math.ceil(imageProps.size / 1024)} Kb</b>
            </Typography>
          )}

          <div>
            <Button variant="elevated" onClick={() => props.setData('')} sx={{ mt: 2 }}>
              Remove
            </Button>
          </div>
        </>
      )}

      {!props.data && (
        <>
          <InputLabel
            htmlFor={props.name}
            sx={{
              px: 1,
              py: 2,
              backgroundColor: drag ? '#FFFFFF' : '#EFF1F9',
              border: '1px dashed #859BD6',
              borderRadius: 1,
              width: '100%',
              height: '100px',
              display: 'inline-block',
              cursor: 'pointer',
            }}
          >
            <div {...dragOptions} style={{ color: '#000000', textAlign: 'center' }}>
              <Typography>
                <FileUploadIcon />
              </Typography>
              <Typography fontSize="12px">
                <span style={{ color: '#2589CC', textDecoration: 'underline' }}>Click to upload</span> or drag and drop
              </Typography>
              <Typography color="#B3B4B5" fontSize="10px">
                Max. file size 1MB
              </Typography>
            </div>
          </InputLabel>
        </>
      )}

      <InputBase
        type="file"
        inputProps={{ accept: 'image/*' }}
        inputRef={fileRef}
        {...(props.format && { inputProps: { accept: props.format } })}
        id={props.name}
        sx={{ display: 'none' }}
        onChange={onChange}
      />
    </>
  );
}
