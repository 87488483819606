import { AxiosResponse } from 'axios';
import { differenceInYears, format, isToday, isYesterday, parseJSON } from 'date-fns';

import {
  DateFormatFull,
  DateFormatInput,
  UserRoleAdmin,
  UserRoleApi,
  UserRoleOperator,
  UserRoleUser,
} from '../constants/utils';
import { LocalizedValues } from '../models/Location';

export function save_file(response: AxiosResponse) {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  const headerLine = response.headers['content-disposition'];
  const filename = headerLine.substring(headerLine.indexOf('"') + 1, headerLine.lastIndexOf('"'));
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function ucfirst(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function check_user_access(requiredRole: string, userRole: string): boolean {
  return (
    userRole === UserRoleAdmin ||
    (requiredRole === UserRoleOperator && userRole === UserRoleOperator) ||
    requiredRole === UserRoleUser ||
    requiredRole === UserRoleApi
  );
}

export function convert_date_full_from_api_full(date: string): string {
  return format(parseJSON(date), DateFormatFull);
}

export function convert_date_from_api_full(date: string): string {
  return format(parseJSON(date), DateFormatInput);
}

export function updated_as_string(date: string): string {
  if (date) {
    const d = parseJSON(date);

    if (isToday(d)) {
      return 'today';
    } else if (isYesterday(d)) {
      return 'yesterday';
    } else if (differenceInYears(new Date(), d) >= 1) {
      return '>1y ago';
    } else {
      return format(d, DateFormatInput);
    }
  }

  return '';
}

export function get_google_url(latitude?: string, longitude?: string, address?: string, zoom?: string): string {
  let url = '';

  if (latitude && longitude) {
    url =
      'https://www.google.com/maps/@' +
      [encodeURIComponent(latitude), encodeURIComponent(longitude), zoom ?? '13z'].join(',');
  } else if (address) {
    url = 'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(address);
  }

  return url;
}

export function collect_address(
  street?: string,
  streetNumber?: string,
  postcode?: string,
  city?: string,
  country?: string
): string {
  if (country && city && postcode && street && streetNumber) {
    return [street, streetNumber, postcode, get_name_without_code(city), get_name_without_code(country)]
      .filter((value) => !!value)
      .join(', ');
  }

  return '';
}

export function get_name_without_code(name: string): string {
  if (name.indexOf('(') !== -1) {
    return name.substring(0, name.indexOf('(')).trim();
  }

  return name;
}

export const getAliasesDiff = (
  aliases: LocalizedValues,
  newAliases: LocalizedValues
): { added: LocalizedValues; removed: LocalizedValues } => {
  const added: LocalizedValues = {};
  const removed: LocalizedValues = {};

  for (const lang in newAliases) {
    const newLangAliases = newAliases[lang];
    const prevLangAliases = aliases[lang] || [];

    const addedAliases = newLangAliases.filter((alias) => !prevLangAliases.includes(alias));
    const removedAliases = prevLangAliases.filter((alias) => !newLangAliases.includes(alias));

    if (addedAliases.length > 0) {
      added[lang] = addedAliases;
    }

    if (removedAliases.length > 0) {
      removed[lang] = removedAliases;
    }
  }

  return { added, removed };
};

export const arraysEqual = (arr1: string[], arr2: string[]): boolean => {
  if (!arr1.length || !arr2.length) return false;
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
};
