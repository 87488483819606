import { GridColDef } from '@mui/x-data-grid';

import DataGridRestSort from '../../../components/DataGridRestSort';
import GridFieldActions from '../../../components/GridFieldActions';
import GridFieldIdentifier from '../../../components/GridFieldIdentifier';
import GridFieldName from '../../../components/GridFieldName';
import GridFieldStatus from '../../../components/GridFieldStatus';
import GridFieldType from '../../../components/GridFieldType';
import GridFieldUpdated from '../../../components/GridFieldUpdated';

import { ApiCarriers } from '../../../constants/endpoints';
import { UrlCarriers } from '../../../constants/urls';
import GridFieldCountryName from '../../../components/GridFieldCountryName';

export default function CarriersList() {
  const columns: GridColDef[] = [
    GridFieldStatus(),
    GridFieldIdentifier(),
    GridFieldName(),
    GridFieldType(),
    { field: 'company', headerName: 'Company', flex: 10 },
    GridFieldCountryName({
      codeField: 'headquarterCountryIsoCode',
      field: 'headquarterCountryName',
      headerName: 'Headquarter country',
    }),
    GridFieldUpdated(),
    GridFieldActions({ baseUrlEdit: UrlCarriers }),
  ];

  return (
    <DataGridRestSort
      columns={columns}
      endpoint={ApiCarriers}
      navigation={UrlCarriers}
      rootNode="carriers"
      sortModel={{ field: 'name', sort: 'asc' }}
    />
  );
}
