import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  ClearIcon,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from '../../../imports';

import AutocompleteLoading from '../../../components/Autocomplete/AutocompleteLoading';
import EditButtons from '../../../components/EditButtons';
import HistoryCard from '../../../components/HistoryCard';
import IdentifierField from '../../../components/IdentifierField';
import LoadingPage from '../../../components/LoadingPage';
import NameField from '../../../components/NameField';
import ReviewCard from '../../../components/ReviewCard';
import TranslationsDrawer from '../../../components/TranslationsDrawer';
import TranslationsIcon from '../../../components/TranslationsIcon';

import AutocompleteOption from '../../../models/AutocompleteOption';
import Code from '../../../models/Code';
import { Translations } from '../../../models/Translations';

import { ApiCarriersSearch, ApiCodes } from '../../../constants/endpoints';
import { CodeClasses } from '../../../constants/CodeClasses';
import { CodeInit } from '../../../constants/CodeInit';
import { CodeTypes } from '../../../constants/CodeTypes';
import { DataSendStatusInit } from '../../../constants/DataSendStatus/DataSendStatusInit';
import { LocaleEn } from '../../../constants/utils';
import { UrlCodes } from '../../../constants/urls';

import useAxios from '../../../services/useAxios';
import { useDataSendStatus } from '../../../services/useDataSendStatus';
import usePageConfig from '../../../services/usePageConfig';

export default function CodesEdit() {
  const [loading, setLoading] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [action, setAction] = useState('add');
  const [formData, setFormData] = useState<Code>(CodeInit);
  const [loadedFormData, setLoadedFormData] = useState<Code>(CodeInit);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [drawers, setDrawers] = useState({
    name: false,
    description: false,
  });

  const { setDataSendStatus, checkResponseError } = useDataSendStatus();
  const { setTitle } = usePageConfig();
  const navigate = useNavigate();
  const { codeId } = useParams();
  const axiosHelper = useAxios();

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    const { value, name } = e.target;

    setFormData((prevState) => ({ ...prevState, [name]: value }));
  }

  const onCarrierChangeValue = (newValue: AutocompleteOption | null) => {
    setFormData((prevState) => ({
      ...prevState,
      ...(newValue
        ? { carrier: newValue.id, carrierName: newValue.name }
        : {
            carrier: '',
            countryName: '',
          }),
    }));
  };

  const onClassChange = (event: SelectChangeEvent) => {
    setFormData((prevState) => ({ ...prevState, class: event.target.value as string }));
  };

  const onTypeChange = (event: SelectChangeEvent) => {
    setFormData((prevState) => ({ ...prevState, type: event.target.value as string }));
  };

  const onMultiselectChange = (name: string, newValue: string[]) => {
    setFormData((prevState) => ({ ...prevState, [name]: newValue }));
  };

  const updateLoadedFormData = useCallback(
    (data: Code) => {
      setLoadedFormData(data);
      setFormData(data);
      setTitle(data.name || data.identifier);
    },
    [setTitle]
  );

  function getDataFromResponse(response: any): Code {
    return {
      id: (response.id as string) ?? '0',
      identifier: (response.identifier as string) ?? '',
      name: (response.name as string) ?? '',
      key: (response.key as string) ?? '',
      type: (response.type as string) ?? '',
      carrier: (response.specific?.carrier?.identifier as string) ?? '',
      carrierName: (response.carrierName as string) ?? '',
      class: (response.specific?.class as string) ?? '',
      tags: (response.tags as string[]) ?? [],
      created: response.created as string,
      updated: response.updated as string,
      createdBy: response.createdBy as string,
      updatedBy: response.updatedBy as string,
      status: (response.review?.status as string) ?? '',
      notes: (response.review?.notes as string) ?? '',
      statusUpdated: response.review?.statusUpdated as string,
      statusUpdatedBy: response.review?.statusUpdatedBy as string,
      translations: response?.translations || {},
    };
  }

  const handleTranslationsNameChange = (data: Translations): void => {
    setFormData((prevState) => ({
      ...prevState,
      translations: {
        ...prevState.translations,
        name: data,
      },
    }));
  };

  const handleTranslationsDescriptionChange = (data: Translations): void => {
    setFormData((prevState) => ({
      ...prevState,
      translations: {
        ...prevState.translations,
        description: data,
      },
    }));
  };

  const handleToggleDrawer = (drawer: string, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setDrawers((prev) => ({ ...prev, [drawer]: open }));
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setDataSendStatus(DataSendStatusInit);
    setSendingData(true);

    let sendData = {
      ...formData,
      specific: { carrier: { identifier: formData.carrier }, class: formData.class },
      review: { status: formData.status, notes: formData.notes },
    };

    let request: Promise<any>;
    if (action === 'edit') {
      request = axiosHelper.put(ApiCodes + '/' + codeId, sendData);
    } else {
      request = axiosHelper.post(ApiCodes, sendData);
    }

    request
      .then((response) => {
        if (action === 'edit') {
          setDataSendStatus({ open: true, success: true, message: 'Data updated' });
          const d = getDataFromResponse((Object.values(response.data.data.codes)[0] as Code) ?? CodeInit);
          updateLoadedFormData(d);
        } else {
          setDataSendStatus({ open: true, success: true, message: 'Code created' });
          navigate(UrlCodes);
        }
      })
      .catch(checkResponseError)
      .finally(() => setSendingData(false));
  };

  const loadFormData = useCallback(() => {
    if (codeId !== '0') {
      setLoading(true);
      axiosHelper
        .get(ApiCodes + '/' + codeId)
        .then((response) => {
          const d = getDataFromResponse((Object.values(response.data.data.codes)[0] as Code) ?? CodeInit);
          updateLoadedFormData(d);
        })
        .catch(checkResponseError)
        .finally(() => setLoading(false));
    } else {
      setTitle('Add code');
    }
  }, [codeId, axiosHelper, checkResponseError, setTitle, updateLoadedFormData]);

  useEffect(() => {
    if (codeId !== '0') {
      setAction('edit');
    } else {
      setAction('add');
    }

    loadFormData();
  }, [codeId, loadFormData]);

  function deleteDialogCallback(result: boolean) {
    setDeleteDialogOpen(false);

    if (result) {
      setLoading(true);

      axiosHelper
        .delete(ApiCodes + '/' + codeId)
        .then(() => {
          setDataSendStatus({ open: true, success: true, message: 'Code deleted' });
          navigate(UrlCodes);
        })
        .catch(checkResponseError)
        .finally(() => setLoading(false));
    }
  }

  return (
    <>
      {loading && <LoadingPage />}
      {!loading && (
        <Box component="form" onSubmit={handleSubmit} sx={{ margin: 'auto', maxWidth: 1024 }}>
          <Grid container columns={2} rowSpacing={0} columnSpacing={'15px'}>
            <Grid item lg={1} xs={2}>
              <Card variant="island">
                <CardHeader title="Code" />
                <CardContent>
                  <Grid container columns={2} spacing={1} sx={{ marginBottom: 2.5 }}>
                    <Grid item lg={1} xs={1}>
                      <IdentifierField value={formData.identifier} />
                    </Grid>
                  </Grid>

                  <Divider />

                  <FormControl margin="dense" sx={{ marginTop: 2 }} fullWidth>
                    <InputLabel id="type">Type</InputLabel>
                    <Select
                      labelId="type"
                      id="type"
                      value={formData.type}
                      label="Type"
                      name="type"
                      onChange={onTypeChange}
                    >
                      {CodeTypes.map((v) => {
                        return (
                          <MenuItem value={v} key={v} selected={v === formData.type}>
                            {v}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <FormControl margin="dense" fullWidth sx={{ marginBottom: 2 }}>
                    <TextField
                      fullWidth
                      name="key"
                      id="key"
                      label="Key"
                      type="text"
                      value={formData.key}
                      onChange={onChange}
                    />
                  </FormControl>

                  <Divider />

                  <AutocompleteLoading
                    urlPrefix={ApiCarriersSearch}
                    label="Airline specific"
                    value={formData.carrier ?? ''}
                    valueName={formData.carrierName ?? ''}
                    onChangeValue={onCarrierChangeValue}
                    minCharacters={2}
                    inputSx={{ marginTop: 2 }}
                  />

                  <FormControl margin="dense" sx={{ marginBottom: 2 }} fullWidth>
                    <InputLabel id="class">Class specific</InputLabel>
                    <Select
                      labelId="class"
                      id="class"
                      value={formData.class}
                      label="Class specific"
                      name="class"
                      onChange={onClassChange}
                    >
                      <MenuItem value="" key="-1" selected={!formData.class}>
                        &nbsp;
                      </MenuItem>
                      {CodeClasses.map((v) => {
                        return (
                          <MenuItem value={v} key={v} selected={v === formData.class}>
                            {v}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <Divider />

                  <Box sx={{ marginTop: 2 }} />
                  <NameField
                    translations={formData.translations.name}
                    value={(formData.translations.name && formData.translations.name[LocaleEn]) || ''}
                    onClick={handleToggleDrawer('name', true)}
                  />

                  <TranslationsDrawer
                    name="name"
                    label="Name"
                    open={drawers.name}
                    translations={formData.translations.name}
                    onChange={handleTranslationsNameChange}
                    onClose={() => setDrawers((prev) => ({ ...prev, name: false }))}
                  />

                  <FormControl margin="dense" sx={{ marginBottom: 2 }} fullWidth>
                    <InputLabel htmlFor="description">Description additional</InputLabel>
                    <OutlinedInput
                      fullWidth
                      readOnly
                      multiline
                      rows={4}
                      name="description"
                      id="description"
                      label="Description additional"
                      type="text"
                      value={(formData.translations.description && formData.translations.description[LocaleEn]) || ''}
                      onClick={handleToggleDrawer('description', true)}
                      endAdornment={
                        <InputAdornment position="end" sx={{ position: 'absolute', top: 20, right: 15 }}>
                          <IconButton edge="end">
                            <TranslationsIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      sx={{
                        '& .MuiInputBase-input': {
                          color: 'black',
                        },
                      }}
                    />
                  </FormControl>

                  <TranslationsDrawer
                    name="description"
                    label="Description additional"
                    rows={3}
                    open={drawers.description}
                    translations={formData.translations.description}
                    onChange={handleTranslationsDescriptionChange}
                    onClose={() => setDrawers((prev) => ({ ...prev, description: false }))}
                  />

                  <Divider />

                  <Autocomplete
                    fullWidth
                    multiple
                    id="tags"
                    options={[]}
                    freeSolo
                    value={formData.tags}
                    renderTags={(value: readonly string[], getTagProps) =>
                      value.map((option: string, index: number) => (
                        <Chip
                          variant="transfer"
                          color="primary"
                          label={option}
                          deleteIcon={<ClearIcon />}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} sx={{ marginTop: 2 }} variant="outlined" label="Tags" margin="dense" />
                    )}
                    onChange={(_e, value) => {
                      onMultiselectChange('tags', value);
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={1} xs={2}>
              <ReviewCard
                action={action}
                initialStatus={loadedFormData.status}
                statusUpdated={formData.statusUpdated}
                statusUpdatedBy={formData.statusUpdatedBy}
                created={formData.created}
                notes={formData.notes}
                status={formData.status}
                onChange={(data) => setFormData({ ...formData, notes: data.notes, status: data.status })}
              />
              {action === 'edit' && (
                <HistoryCard
                  createdBy={formData.createdBy}
                  created={formData.created}
                  updatedBy={formData.updatedBy}
                  updated={formData.updated}
                />
              )}
            </Grid>
          </Grid>

          <EditButtons
            action={action}
            itemName={loadedFormData.name}
            entityName="code"
            sendingData={sendingData}
            deleteDialogOpen={deleteDialogOpen}
            deleteDialogCallback={deleteDialogCallback}
            onReset={() => updateLoadedFormData(loadedFormData)}
            onDelete={() => setDeleteDialogOpen(true)}
          />
        </Box>
      )}
    </>
  );
}
