import { Chip, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '../imports';

import TranslationsIcon from './TranslationsIcon';

import { Translations } from '../models/Translations';

import { useAuth } from '../services/useAuth';

interface NameFieldProps {
  value: string;
  onClick: (event: React.KeyboardEvent | React.MouseEvent) => void;
  translations?: Translations;
}

export default function NameField({ value, onClick, translations = {} }: NameFieldProps) {
  const { user } = useAuth();

  const translationsCount = Object.values(translations).filter((value) => value !== '').length;

  const globalChip =
    translationsCount === Object.entries(user.supported_locales).length &&
    Object.values(translations).every((x) => x === Object.values(translations)[0]);

  return (
    <FormControl margin="dense" fullWidth>
      <InputLabel htmlFor="name">Name</InputLabel>
      <OutlinedInput
        fullWidth
        readOnly
        name="name"
        id="name"
        label="Name"
        type="text"
        value={value}
        onClick={onClick}
        endAdornment={
          <>
            <InputAdornment position="end">
              {globalChip && (
                <Chip
                  sx={{
                    width: '56px',
                    height: '22px',
                    color: '#B7B8B9',
                    backgroundColor: '#fff',
                    border: '1px solid #B7B8B9',
                    borderRadius: '8px',
                  }}
                  label="global"
                />
              )}
            </InputAdornment>
            <InputAdornment position="end">
              <IconButton edge="end">
                <TranslationsIcon
                  color={
                    translationsCount === 0
                      ? 'GRAY'
                      : translationsCount === Object.entries(user.supported_locales).length
                      ? 'GREEN'
                      : 'ORANGE'
                  }
                />
              </IconButton>
            </InputAdornment>
          </>
        }
        sx={{
          '& .MuiInputBase-input': {
            color: 'rgb(0, 0, 0, 0.87)',
          },
        }}
      />
    </FormControl>
  );
}
