import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  ClearIcon,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from '../../../imports';

import AliasesAutocomplete from '../../../components/AliasesAutocomplete';
import AutocompleteLoading from '../../../components/Autocomplete/AutocompleteLoading';
import EditButtons from '../../../components/EditButtons';
import HistoryCard from '../../../components/HistoryCard';
import IdentifierField from '../../../components/IdentifierField';
import LoadingPage from '../../../components/LoadingPage';
import NameField from '../../../components/NameField';
import ReviewCard from '../../../components/ReviewCard';
import SimpleNumberField from '../../../components/SimpleNumberField';
import TranslationsDrawer from '../../../components/TranslationsDrawer';
import TranslationsDrawerMultiple from '../../../components/TranslationsDrawerMultiple';

import AutocompleteOption from '../../../models/AutocompleteOption';
import Country, { LocalizedValues } from '../../../models/Country';
import { Translations } from '../../../models/Translations';

import { ApiContinentsSearch, ApiCountries } from '../../../constants/endpoints';
import { CountryInit } from '../../../constants/CountryInit';
import { DataSendStatusInit } from '../../../constants/DataSendStatus/DataSendStatusInit';
import { DateFormats } from '../../../constants/DateFormats';
import { DrivingSides } from '../../../constants/DrivingSides';
import { LocaleEn } from '../../../constants/utils';
import { UrlCountries } from '../../../constants/urls';

import useAxios from '../../../services/useAxios';
import { useDataSendStatus } from '../../../services/useDataSendStatus';
import usePageConfig from '../../../services/usePageConfig';

import { getAliasesDiff } from '../../../utils/utils';

export default function CountriesEdit() {
  const [loading, setLoading] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [action, setAction] = useState('add');
  const [formData, setFormData] = useState<Country>(CountryInit);
  const [loadedFormData, setLoadedFormData] = useState<Country>(CountryInit);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [drawers, setDrawers] = useState({
    name: false,
    aliases: false,
  });

  const [prevAliases, setPrevAliases] = useState<LocalizedValues | undefined>();

  const { setDataSendStatus, checkResponseError } = useDataSendStatus();
  const { setTitle } = usePageConfig();
  const navigate = useNavigate();
  const { countryId } = useParams();
  const axiosHelper = useAxios();

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    const { value, name } = e.target;

    setFormData((prevState) => ({ ...prevState, [name]: value }));
  }

  const onContinentChangeValue = (newValue: AutocompleteOption | null) => {
    setFormData((prevState) => ({
      ...prevState,
      continent: newValue
        ? {
            identifier: newValue.id,
            name: newValue.name,
          }
        : { identifier: '', name: '', code: '' },
    }));
  };

  const updateLoadedFormData = useCallback(
    (data: Country) => {
      setLoadedFormData(data);
      setFormData(data);
      setTitle(data.name);
    },
    [setTitle]
  );

  function getDataFromResponse(response: any): Country {
    return {
      id: (response.id as string) ?? '0',
      identifier: (response.identifier as string) ?? '0',
      iso: (response.iso as string) ?? '',
      name: (response.name as string) ?? '',
      official: (response.official as string) ?? '',
      aliases: response.aliases ?? [],
      continent: response.continent ?? {},
      alpha3: (response.alpha3 as string) ?? '',
      numeric: (response.numeric as string) ?? '',
      callingCodes: (response.callingCodes as string[]) ?? [],
      demonym: (response.demonym as string) ?? '',
      internetTlds: (response.internetTlds as string[]) ?? [],
      drivingSide: (response.drivingSide as string) ?? '',
      dateFormat: (response.dateFormat as string) ?? '',
      created: response.created as string,
      updated: response.updated as string,
      createdBy: response.createdBy as string,
      updatedBy: response.updatedBy as string,
      status: (response.review?.status as string) ?? '',
      notes: (response.review?.notes as string) ?? '',
      statusUpdated: response.review?.statusUpdated as string,
      statusUpdatedBy: response.review?.statusUpdatedBy as string,
      translations: response.translations || {},
    };
  }

  const handleTranslationsChange = (data: Translations): void => {
    setFormData((prevState) => ({
      ...prevState,
      name: data[LocaleEn] || '',
      translations: {
        ...prevState.translations,
        name: data,
      },
    }));
  };

  const handleTranslationsAliasesChange = (data: LocalizedValues) => {
    setFormData((prevState) => ({
      ...prevState,
      aliases: {
        ...prevState.aliases,
        ...data,
      },
    }));
  };

  const handleToggleDrawer = (drawer: string, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setDrawers((prev) => ({ ...prev, [drawer]: open }));
  };

  const onAliasesChangeValue = (newValue: string[]) => {
    setFormData((prevState) => ({
      ...prevState,
      aliases: { en_GB: newValue || [] },
    }));
  };

  const onDrivingSideChange = (event: SelectChangeEvent) => {
    setFormData((prevState) => ({ ...prevState, drivingSide: event.target.value as string }));
  };

  const onDateFormatChange = (event: SelectChangeEvent) => {
    setFormData((prevState) => ({ ...prevState, dateFormat: event.target.value as string }));
  };

  const onMultiselectChange = (name: string, newValue: string[]) => {
    setFormData((prevState) => ({ ...prevState, [name]: newValue }));
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setDataSendStatus(DataSendStatusInit);
    setSendingData(true);

    const { added, removed } = getAliasesDiff(prevAliases || {}, formData.aliases);

    let sendData = {
      ...formData,
      continent: formData.continent.identifier,
      aliases: {
        add: added,
        remove: removed,
      },
      review: { status: formData.status, notes: formData.notes },
    };

    let request: Promise<any>;
    if (action === 'edit') {
      request = axiosHelper.put(ApiCountries + '/' + countryId, sendData);
    } else {
      request = axiosHelper.post(ApiCountries, sendData);
    }

    request
      .then((response) => {
        if (action === 'edit') {
          setDataSendStatus({ open: true, success: true, message: 'Data updated' });
          updateLoadedFormData(getDataFromResponse(Object.values(response.data.data.countries)[0]) ?? CountryInit);
        } else {
          setDataSendStatus({ open: true, success: true, message: 'Country created' });
          navigate(UrlCountries);
        }
      })
      .catch(checkResponseError)
      .finally(() => setSendingData(false));
  };

  const loadFormData = useCallback(() => {
    if (countryId !== '0') {
      setLoading(true);
      axiosHelper
        .get(ApiCountries + '/' + countryId)
        .then((response) => {
          setPrevAliases(response.data.data.countries[0].aliases);
          updateLoadedFormData(getDataFromResponse(Object.values(response.data.data.countries)[0]) ?? CountryInit);
        })
        .catch(checkResponseError)
        .finally(() => setLoading(false));
    } else {
      setTitle('Add country');
    }
  }, [countryId, axiosHelper, checkResponseError, setTitle, updateLoadedFormData]);

  useEffect(() => {
    if (countryId !== '0') {
      setAction('edit');
    } else {
      setAction('add');
    }

    loadFormData();
  }, [countryId, loadFormData]);

  function deleteDialogCallback(result: boolean) {
    setDeleteDialogOpen(false);

    if (result) {
      setLoading(true);

      axiosHelper
        .delete(ApiCountries + '/' + countryId)
        .then(() => {
          setDataSendStatus({ open: true, success: true, message: 'Country deleted' });
          navigate(UrlCountries);
        })
        .catch(checkResponseError)
        .finally(() => setLoading(false));
    }
  }

  return (
    <>
      {loading && <LoadingPage />}
      {!loading && (
        <Box component="form" onSubmit={handleSubmit} sx={{ margin: 'auto', maxWidth: 1024 }}>
          <Grid container columns={2} rowSpacing={0} columnSpacing={'15px'}>
            <Grid item lg={1} xs={2}>
              <Card variant="island">
                <CardHeader title="Country" />
                <CardContent>
                  <Grid container columns={2} spacing={1}>
                    <Grid item lg={1} xs={1}>
                      <IdentifierField value={formData.identifier} />
                    </Grid>
                  </Grid>
                  <Grid container columns={2} spacing={1}>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel htmlFor="iso">ISO code</InputLabel>
                        <OutlinedInput
                          fullWidth
                          name="iso"
                          id="iso"
                          label="ISO code"
                          type="text"
                          value={formData.iso}
                          onChange={onChange}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={1} xs={1}>
                      <SimpleNumberField
                        fullWidth
                        margin="dense"
                        name="numeric"
                        id="numeric"
                        label="ISO numeric code"
                        type="text"
                        value={formData.numeric}
                        onChange={onChange}
                        onlyInteger={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container columns={2} spacing={1} sx={{ marginBottom: 2 }}>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel htmlFor="alpha3">ISO alpha-3 code</InputLabel>
                        <OutlinedInput
                          fullWidth
                          name="alpha3"
                          id="alpha3"
                          label="ISO alpha-3 code"
                          type="text"
                          value={formData.alpha3}
                          onChange={onChange}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Divider />

                  <Box sx={{ marginTop: 2 }} />

                  <NameField
                    translations={formData.translations.name}
                    value={(formData.translations.name && formData.translations.name[LocaleEn]) || ''}
                    onClick={handleToggleDrawer('name', true)}
                  />

                  <TranslationsDrawer
                    name="name"
                    label="Name"
                    open={drawers.name}
                    globalDesignation={true}
                    translations={formData.translations.name}
                    onChange={handleTranslationsChange}
                    onClose={() => setDrawers((prev) => ({ ...prev, name: false }))}
                  />

                  <FormControl margin="dense" fullWidth>
                    <InputLabel htmlFor="official">Official</InputLabel>
                    <OutlinedInput
                      fullWidth
                      name="official"
                      id="official"
                      label="Official"
                      type="text"
                      value={formData.official}
                      onChange={onChange}
                    />
                  </FormControl>

                  <AliasesAutocomplete
                    value={formData.aliases}
                    onChange={onAliasesChangeValue}
                    onTranslationsClick={handleToggleDrawer('aliases', true)}
                    translations={formData.aliases}
                  />

                  <TranslationsDrawerMultiple
                    name="Aliases"
                    label="Aliases"
                    open={drawers.aliases}
                    globalDesignation={true}
                    translations={formData.aliases}
                    onChange={handleTranslationsAliasesChange}
                    onClose={() => setDrawers((prev) => ({ ...prev, aliases: false }))}
                  />

                  <Divider />

                  <Autocomplete
                    fullWidth
                    multiple
                    id="callingCodes"
                    options={[]}
                    freeSolo
                    value={formData.callingCodes}
                    renderTags={(value: readonly string[], getTagProps) =>
                      value.map((option: string, index: number) => (
                        <Chip
                          variant="transfer"
                          color="primary"
                          label={option}
                          deleteIcon={<ClearIcon />}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ marginTop: 2 }}
                        variant="outlined"
                        label="Calling codes"
                        margin="dense"
                      />
                    )}
                    onChange={(_e, value) => {
                      onMultiselectChange('callingCodes', value);
                    }}
                  />

                  <Autocomplete
                    fullWidth
                    multiple
                    id="internetTlds"
                    options={[]}
                    freeSolo
                    value={formData.internetTlds}
                    renderTags={(value: readonly string[], getTagProps) =>
                      value.map((option: string, index: number) => (
                        <Chip
                          variant="transfer"
                          color="primary"
                          label={option}
                          deleteIcon={<ClearIcon />}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Internet TLDs" margin="dense" />
                    )}
                    onChange={(_e, value) => {
                      onMultiselectChange('internetTlds', value);
                    }}
                  />

                  <Grid container columns={2} spacing={1}>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel htmlFor="demonym">Demonym</InputLabel>
                        <OutlinedInput
                          fullWidth
                          name="demonym"
                          id="demonym"
                          label="Demonym"
                          type="text"
                          value={formData.demonym}
                          onChange={onChange}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" sx={{ marginBottom: 2 }} fullWidth>
                        <InputLabel id="drivingSide">Driving side</InputLabel>
                        <Select
                          labelId="drivingSide"
                          id="drivingSide"
                          value={formData.drivingSide}
                          label="Driving side"
                          name="drivingSide"
                          onChange={onDrivingSideChange}
                        >
                          {DrivingSides.map((v) => {
                            return (
                              <MenuItem value={v} key={v} selected={v === formData.drivingSide}>
                                {v}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Divider />

                  <FormControl margin="dense" sx={{ marginTop: 2 }} fullWidth>
                    <InputLabel id="dateFormat">Date format</InputLabel>
                    <Select
                      labelId="dateFormat"
                      id="dateFormat"
                      value={formData.dateFormat}
                      label="Date format"
                      name="dateFormat"
                      onChange={onDateFormatChange}
                    >
                      {DateFormats.map((v) => {
                        return (
                          <MenuItem value={v} key={v} selected={v === formData.dateFormat}>
                            {v}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </CardContent>
              </Card>
            </Grid>

            <Grid item lg={1} xs={2}>
              <Card variant="island">
                <CardHeader title="Geo" />
                <CardContent>
                  <AutocompleteLoading
                    urlPrefix={ApiContinentsSearch}
                    label="Continent"
                    loadAll={true}
                    value={formData.continent.identifier ?? ''}
                    valueName={formData.continent.name ?? ''}
                    onChangeValue={onContinentChangeValue}
                  />
                </CardContent>
              </Card>

              <ReviewCard
                action={action}
                initialStatus={loadedFormData.status}
                statusUpdated={formData.statusUpdated}
                statusUpdatedBy={formData.statusUpdatedBy}
                created={formData.created}
                notes={formData.notes}
                status={formData.status}
                onChange={(data) => setFormData({ ...formData, notes: data.notes, status: data.status })}
              />

              {action === 'edit' && (
                <HistoryCard
                  createdBy={formData.createdBy}
                  created={formData.created}
                  updatedBy={formData.updatedBy}
                  updated={formData.updated}
                />
              )}
            </Grid>
          </Grid>

          <EditButtons
            action={action}
            itemName={loadedFormData.name}
            entityName="country"
            sendingData={sendingData}
            deleteDialogOpen={deleteDialogOpen}
            deleteDialogCallback={deleteDialogCallback}
            onReset={() => updateLoadedFormData(loadedFormData)}
            onDelete={() => setDeleteDialogOpen(true)}
          />
        </Box>
      )}
    </>
  );
}
