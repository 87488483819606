import { ChangeEvent, useEffect, useState } from 'react';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from '../imports';

import ReviewData from '../models/ReviewData';

import { ReviewDataInit } from '../constants/ReviewDataInit';
import { ReviewStatuses } from '../constants/ReviewStatuses';
import { UserRoleOperator } from '../constants/utils';

import { useAuth } from '../services/useAuth';

import { check_user_access, convert_date_full_from_api_full } from '../utils/utils';
import StatusIcon from './StatusIcon';

interface ReviewCardProps {
  action: string;
  initialStatus: string | undefined;
  statusUpdated: string;
  statusUpdatedBy: string;
  created: string;
  notes: string;
  status: string | undefined;

  onChange(data: ReviewData): void;
}

export default function ReviewCard(props: ReviewCardProps) {
  const [formData, setFormData] = useState<ReviewData>(ReviewDataInit);
  const { user } = useAuth();

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    const { value, name } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    props.onChange({
      ...formData,
      [name]: value,
    });
  }

  const onStatusChange = (event: SelectChangeEvent) => {
    const newStatus = event.target.value as string;

    setFormData((prevFormData) => ({
      ...prevFormData,
      status: newStatus,
    }));

    props.onChange({
      ...formData,
      status: newStatus,
    });
  };

  useEffect(() => {
    setFormData({ notes: props.notes, status: props.status });
  }, [props.notes, props.status]);

  return (
    <>
      {check_user_access(UserRoleOperator, user.role) && (
        <Card
          variant="island"
          sx={{
            border: '2px solid ' + (props.initialStatus === 'To verify' ? '#FFA60E' : '#77AA3D'),
          }}
        >
          <CardHeader title="Review process" action={<StatusIcon status={props.initialStatus ?? ''} />} />
          <CardContent>
            <FormControl margin="dense" sx={{ marginBottom: 2 }} fullWidth>
              <InputLabel htmlFor="notes">Internal notes</InputLabel>
              <OutlinedInput
                sx={{ backgroundColor: '#FFF9D5' }}
                fullWidth
                multiline
                rows={4}
                name="notes"
                id="notes"
                label="Internal notes"
                type="text"
                value={formData.notes}
                onChange={onChange}
              />
            </FormControl>

            <Divider />

            <FormControl margin="dense" sx={{ marginTop: 2 }} fullWidth>
              <InputLabel id="status">Status</InputLabel>
              <Select
                labelId="status"
                id="status"
                value={formData.status}
                label="Status"
                name="status"
                disabled={props.action === 'add'}
                onChange={onStatusChange}
              >
                {ReviewStatuses.map((v) => {
                  return (
                    <MenuItem value={v} key={v} selected={v === formData.status}>
                      {v}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            {props.action === 'edit' && (
              <Box margin="dense" sx={{ mt: 1 }}>
                <Typography display="inline" fontSize="13px" color="#B3B4B5" sx={{ fontWeight: 'bold' }}>
                  {!props.statusUpdated && 'Initial status'}
                  {props.statusUpdated > props.created && 'Status changed by'}{' '}
                </Typography>
                <Typography display="inline" fontSize="13px" color="#B3B4B5">
                  {props.statusUpdatedBy && props.statusUpdatedBy + ' • '}
                </Typography>
                <Typography display="inline" fontSize="13px" color="#B3B4B5">
                  {props.statusUpdated ? convert_date_full_from_api_full(props.statusUpdated) : ''}
                </Typography>
              </Box>
            )}
          </CardContent>
        </Card>
      )}
    </>
  );
}
