import { CircleFlag } from 'react-circle-flags';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Box } from '../imports';

interface GridFieldCountryNameProps {
  field?: string;
  headerName?: string;
  codeField?: string;
}

export default function GridFieldCountryName(props?: GridFieldCountryNameProps): GridColDef {
  return {
    field: props?.field ?? 'countryName',
    headerName: props?.headerName ?? 'Country',
    flex: 10,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <>
          {params.row.country && (
            <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
              {params.row.country.iso && (
                <CircleFlag
                  countryCode={params.row.country.iso.toLowerCase()}
                  title={params.row.country.iso}
                  height={18}
                />
              )}
              <span style={{ marginLeft: '8px', lineHeight: '18px' }}>{params.row.country.name}</span>
            </Box>
          )}
          {params.row.headquarterCountry && (
            <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
              {params.row.headquarterCountry.iso && (
                <CircleFlag
                  countryCode={params?.row?.headquarterCountry?.iso?.toLowerCase()}
                  title={params?.row?.headquarterCountry?.iso}
                  height={18}
                />
              )}
              <span style={{ marginLeft: '8px', lineHeight: '18px' }}>{params.row.headquarterCountry.name}</span>
            </Box>
          )}
        </>
      );
    },
  };
}
